.root-container {
    display: flex;
    flex-direction: row;
    max-height: 100vh;
}

.root-content {
    flex-grow: 1;
    position: relative;
    overflow: auto;
}

.sidebar {
    background-color: var(--background-dark-color);
    min-height: 100vh;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 1rem;
}

.sidebar.condensed {
    /* width: 40px; */
}

.sidebar.expanded {
    max-width: 240px;
    flex-grow: 0;
    flex-shrink: 0;
}

.sidebar.condensed .items-container {
    display: none;
}

.sidebar li:not(:first-child) {
    margin-top: 1rem;
}

.sidebar li a {
    color: var(--foreground-color);
    text-decoration: none;
}

.sidebar li a.active {
    color: var(--purple-color);
}

.sidebar .menu-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}

.sidebar .items-container {
    margin-top: 2rem;
}
