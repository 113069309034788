.selectable-list-item {
    padding: 0.25rem 1rem;
    box-sizing: border-box;
}

.selectable-list-item:hover {
    background-color: var(--background-color);
}

.selectable-list-item .preview-icon:hover {
    color: var(--purple-color) !important;
}
