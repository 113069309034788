@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

:root {
    --background-color: #282a36;
    --background-dark-color: #1e2029;
    --foreground-color: #f8f8f2;
    --green-color: #50fa7b;
    --cyan-color: #8be9fd;
    --pink-color: #ff79c6;
    --purple-color: #bd93f9;
    --purple-dark-color: #a76ef7;
    --yellow-color: #f1fa8c;
    --orange-color: #ffb86c;
    --red-color: #ff5555;
    --comment-color: #6272a4;
    --selection-color: #44475a50;
    --selection-color-opaque: #44475a;
    --default-font: 'JetBrains Mono', 'Source Code Pro', -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        sans-serif;

    font-family:
        'Source Code Pro',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    --toastify-color-dark: var(--background-dark-color);
    --toastify-text-color-dark: var(--foreground-color);
    --toastify-color-info: var(--cyan-color);
    --toastify-color-success: var(--green-color);
    --toastify-color-warning: var(--yellow-color);
    --toastify-color-error: var(--red-color);
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
}

body {
    margin: 0;
    font-family: var(--default-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
}

body span,
body th,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p,
body a,
body input,
body button,
body label,
body select,
body textarea {
    font-family: var(--default-font);
}

code {
    font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.color-dimmed {
    color: var(--comment-color);
}

.color-purple {
    color: var(--purple-color);
}

.color-green {
    color: var(--green-color);
}

.color-orange {
    color: var(--orange-color);
}

.color-red {
    color: var(--red-color);
}

.color-foreground {
    color: var(--foreground-color);
}

.small-text {
    font-size: 0.825rem;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: var(--comment-color);
    font-family: var(--default-font);
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: var(--comment-color);
    font-family: var(--default-font);
    opacity: 0.5;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    color: var(--comment-color);
    font-family: var(--default-font);
}

* {
    scrollbar-color: var(--comment-color) var(--background-dark-color); /* thumb track */
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--selection-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--comment-color);
}

.navbar {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.navbar span a {
    color: var(--comment-color);
    cursor: pointer;
    text-decoration: none;
}

.navbar span a.active {
    color: var(--purple-color);
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.1);
}
