.table {
    margin-top: 1rem;
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
}

.table .table-cell {
    padding: 0 0.25rem;
}

.table .table-cell:first-child {
    padding-left: 0;
}

.table .table-cell:last-child {
    padding-right: 0;
}

.table .table-cell_info {
    color: var(--foreground-color);
}

.table .table-cell_comment {
    color: var(--comment-color);
}

.table .table-cell_delete {
    color: var(--red-color);
}
