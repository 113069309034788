.actions-menu-item {
    color: var(--foreground-color);
    cursor: pointer;
}

.actions-menu-item__confirming {
    color: var(--red-color);
    cursor: pointer;
}

.actions-menu-item:hover {
    color: var(--purple-color);
}
