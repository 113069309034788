.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.breadcrumbs .path-item {
    color: var(--comment-color);
    font-size: 0.825rem;
    text-decoration: none;
    margin-right: 0.5rem;
}

.breadcrumbs .path-item:hover {
    color: var(--purple-color);
}

.breadcrumbs .path-separator {
    color: var(--comment-color);
    font-size: 0.825rem;
    margin-right: 0.5rem;
}
