.table-header {
    color: var(--comment-color);
    padding: 0 0.25rem;
    text-align: left;
}

.table-header:first-child {
    padding-left: 0;
}

.table-header:last-child {
    padding-right: 0;
}
