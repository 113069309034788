.text-button {
    color: var(--purple-color);
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    font-size: inherit;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.text-button.disabled {
    color: var(--comment-color);
    cursor: not-allowed;
}

.text-button:hover:not(.disabled) {
    color: var(--purple-dark-color);
}

.text-button__danger {
    color: var(--red-color);
}

.text-button__danger:hover:not(.disabled) {
    color: var(--red-color);
    opacity: 0.8;
}

.text-button__comment {
    color: var(--comment-color);
}

.text-button__comment:hover:not(.disabled) {
    color: var(--comment-color);
    opacity: 0.8;
}

.text-button__primary {
    color: var(--green-color);
}

.text-button__primary:hover:not(.disabled) {
    color: var(--green-color);
    opacity: 0.8;
}

.text-button__active {
    color: var(--cyan-color);
}

.text-button__active:hover:not(.disabled) {
    color: var(--cyan-color);
    opacity: 0.8;
}

.text-button__info {
    color: var(--foreground-color);
}

.text-button__info:hover:not(.disabled) {
    color: var(--purple-color);
    opacity: 0.8;
}
