.member-item {
    padding: 0.25rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.member-item:hover {
    background-color: var(--background-color);
}
