
.text-link {
    color: var(--foreground-color);
    text-decoration: none;
    cursor: pointer;
}

.text-link:hover {
    color: var(--purple-color) !important;
}

.dimmed-pressable-icon {
    color: var(--comment-color);
    cursor: pointer;
}
