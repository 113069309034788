.issue-selection-modal-item {
    padding: 0.25rem 1rem;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.issue-selection-modal-item:hover {
    background-color: var(--background-color);
}
